class ProgressBar extends HTMLElement {
  static get observedAttributes() {
    return ["total", "current"];
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  attributeChangedCallback(name, oldValue, newValue) {
    this[name] = newValue;
    this.shadowRoot.innerHTML = this.render();
  }

  style() {
    return `
      :host {
        display: grid;
        align-items: center;
        justify-content: center;
        width: 100%;
        grid-template-columns: 1fr;
        position: relative;
        font-family: "Arial";
      }

      h3 {
        text-align: center;
      }

      #bar {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 0 15px;
        align-content: center;
        justify-items: center;
        align-items: center;
      }

      .meter {
        height: 20px;
        position: relative;
        background: #ecf0f1;
        border-radius: 5px;
        padding: 5px;
        display:grid;
        align-items:center;
        width: 100%;
      }

      span {
        font-size: clamp(10px, 4vw, 18px);
      }

      .current-fill {
        display: block;
        height: 100%;
        background: #1abc9c;
      }

      .current-value {
        position: absolute;
        color: #fff;
        left: 0;
      }
    `;
  }

  connectedCallback() {
    this.title = this.getAttribute("title") || "";
    this.shadowRoot.innerHTML = this.render();
  }

  render() {
    const percentage = (this.current / this.total) * 100;
    const fillWidth = Math.max(10, percentage);
    const valueLeft = Math.max(4, Math.abs(percentage / 2));
    return `
      <style>${this.style()}</style>
      ${(this.title !== "" && `<h3>${this.title}</h3>`) || ""}
      <section id="bar">
        <div class="meter">
          <span class="current-fill" style="width:${fillWidth}%"></span>
          <span class="current-value" style="left:${valueLeft}%">
            ${Math.floor(percentage)}%
          </span>
        </div>
      </section>
      `;
  }
}

customElements.define("progress-bar", ProgressBar);
